<template>
  <el-main>
    <el-form class="set-order" ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item class="item" label="分类名称：" prop="name">
        <el-input v-model="form.name"></el-input>
        <span class="sp2">分类名称建议12符号/6字以内</span>
      </el-form-item>
      <el-form-item class="item" label="上级分类：">
        <el-select
          v-model="form.parent_id"
          clearable
          @change="handleClassChange"
          placeholder="请选择"
        >
          <el-option
            v-for="item in goodsClass"
            :key="item.value"
            :disabled="disabled_type"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span class="sp2">不选择则默认为一级分类</span>
      </el-form-item>
      <el-form-item class="item" label="组内权重：">
        <el-input type="number" :min="0" placeholder="请输入内容" v-model="form.rank"></el-input>
        <span class="sp2">数字越大，排名越靠前；如为空，排序顺序为添加时间</span>
      </el-form-item>
      <el-form-item class="item type-img" label="分类图片 ：">
        <div>
          <ReadyUploadSource
            @getSource="getTypeImg"
            :path="form.picture"
            @removeThis="() => (form.picture = '')"
          ></ReadyUploadSource>
          <p style="margin-top: 10px">
            一级分类建议图片尺寸506px*200px，二级分类建议图片尺寸216px*216px
          </p>
        </div>
      </el-form-item>
      <el-form-item class="item" label="是否显示：">
        <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <Preservation @preservation="onSubmit('form')">
        <router-link to="/goods/GoodsType" slot="return" v-if="form.id" style="margin-right: 10px">
          <el-button>返回</el-button>
        </router-link>
      </Preservation>
    </el-form>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource'
import Preservation from '@/components/preservation'
export default {
  components: {
    ReadyUploadSource,
    Preservation
  },
  data() {
    return {
      form: {
        id: 0,
        is_show: 1,
        name: '',
        picture: '',
        rank: '',
        parent_id: ''
      },
      disabled_type: !1,
      rules: {
        name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }]
      }
    }
  },
  computed: {
    goodsClass() {
      return this.$store.getters.goodsTypeList
    }
  },
  created() {
    this.$store.dispatch('goods/getTypeList')
    if (this.$route.query.info) {
      let data = JSON.parse(this.$route.query.info)
      if (data.grade == '一级') this.disabled_type = !0
      this.form.is_show = data.is_show
      this.form.name = data.name
      this.form.rank = data.rank
      this.form.picture = data.picture
      this.form.parent_id = data.parent_id ? data.parent_id : ''
      this.form.id = data.id
    }
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.parent_id && !this.form.picture)
            return this.$message.warning('请添加分类图片')
          let data = {
            is_show: this.form.is_show,
            picture: this.form.picture,
            rank: this.form.rank,
            parent_id: this.form.parent_id || 0,
            name: this.form.name
          }
          if (this.form.id) data.id = this.form.id
          let url = data.id ? this.$api.goods.editType : this.$api.goods.addType
          this.$axios.post(url, data).then((res) => {
            if (res.code == 0) {
              this.$message.success(`${this.form.id ? '编辑成功' : '添加成功'}`)
              this.$router.push('/goods/GoodsType')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    getTypeImg(val) {
      this.form.picture = val.path
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  padding: 50px 0 0 100px;
  background: #fff;
  .set-order {
    width: 1000px;
    .item {
      .el-input,
      .el-select {
        width: 500px;
      }
      .sp2 {
        margin-left: 20px;
        color: #999;
      }
    }
    .type-img {
      align-items: flex-start;
      span {
        margin-top: 10px;
        margin-left: 0 !important;
      }
    }
  }
}
</style>
